document.addEventListener("DOMContentLoaded", () => {
    let menuItems = document.getElementsByClassName("nav-link");
    checkUrlForValues(menuItems);
});

function checkUrlForValues(menuItems) {
    let currentLocation = window.location.pathname;
    switch (currentLocation) {
        case '/':
            removeAssignment();
            menuItems[0].classList.add('active');
            break;
        case '/fahrzeuge':
            removeAssignment();
            menuItems[1].classList.add('active');
            break;
        case '/kauf':
            removeAssignment();
            menuItems[2].classList.add('active');
            break;
        case '/ueberuns':
            removeAssignment();
            menuItems[3].classList.add('active');
            break;
        case '/team':
            removeAssignment();
            menuItems[4].classList.add('active');
            break;
        case '/kotakt':
            removeAssignment();
            menuItems[5].classList.add('active');
        default:
            break;

    }
}

function removeAssignment() {
    let activeElements = document.getElementsByClassName('active');
    //only if active exists
    if (activeElements.length > 0) {
        activeElements[0].classList.remove('active')
    }
}
